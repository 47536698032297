import React, { useEffect, useState } from "react";
import client from '/graphqlClient';
import { gql } from "graphql-tag";
import dynamic from 'next/dynamic';
const TrendingTiles = dynamic(() => import("../src/components/homepage/trendingTiles"));
const MainSlider =dynamic(()=>import("../src/components/homepage/mainSlider")) ;
// const MobileMainSlider =dynamic(()=>import("../src/components/homepage/mobileMainSlider")) ;
const CustomerReview =dynamic(()=>import("../src/components/homepage/customerReview")) ;
import Head from 'next/head';
import config from "../config";
import axios from "axios";
import LargeMobileSlider from  '../src/components/homepage/largeCollectionMobileSlider.js'
import LatestTrending from  '../src/components/homepage/latestTrending.js'
import { useRouter } from "next/router.js";
const HomeTrilook = dynamic(()=>import("../src/components/homepage/homeTrilook")) ;
import VisualSearch2 from "../src/components/visualSearch2.js";

export const getServerSideProps = async (context) => {
  const [homePagaData, blogData, latestUpdates] = await Promise.all([
  // const [homePagaData, latestUpdates] = await Promise.all([
    client.query({
      query: gql`{
        cmsBlocks(identifiers: ["home_trialbook1", "home_style", "home_trending_tiles", "home_features","home_pattern_color","home_testimonials","home_clients","home_find_dealer","signature_iframe","home_latest_updates","home_style_mobile"]) {
          items {
            identifier
            title
            content
          }
          }
      }`}),
      axios.get(`${config.HEADLESS_BASE_URL}/blog/wp-json/post/v2/category/term=tiles`),
      client.query({
        query: gql`{
          latest_updates(
              params : ""
          )
          {
              mediaurl
              latestmedia{
                  identifier
                  thumbnailimage
                  title
                  short_content
                  read_more_url
              }
              blogurl
              latestblog{
                  identifier
                  thumbnailimage
                  title
                  publish_time
                  short_content
                  read_more_url
              }
          }
      }`}),
  ]);
    return {
        props: { data:homePagaData.data, blogData : blogData.data, latestUpdates: latestUpdates.data, displayLocationPopop:true },
        // props: { data:homePagaData.data, latestUpdates: latestUpdates.data },

    };
	
};

const HomePage = (data) => {
	const [latestUpdateData, setLatestUpdateData] = useState(data.latestUpdates.latest_updates);
  const [relatedBlogData, setRelatedBlogData] = useState(data.blogData.slice(0,2));
  const[displayAllCategories,setDisplayAllCategories]=useState(false)
  // const [relatedBlogData, setRelatedBlogData] = useState("");
  const [scrrenType, setScreenType] = useState('desktop');
  const [base64content, setbase64content] = useState('');

  const router=useRouter()
  const webPageSchema = () => {
    return {
      __html: `
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://www.orientbell.com/",
        "headline":"",
            "breadcrumb": "",
         "name": "Orientbell Tiles: Best Tiles Company in India | Leading Tile Brand",
        "description": "Explore Orientbell, India's leading tile brand, with 100% Tiles, 0% Celebs. Get style & class with a wide range of premium quality tiles at the best price. Shop now!"
    }
      `
    }
  }
  const searchSchema = () => {
    return {
      __html: `{
"@context": "https://schema.org/",
"@type": "WebSite",
"name": "Orientbell Tiles",
"url": "https://www.orientbell.com/",
"potentialAction": {
"@type": "SearchAction",
"target": "https://www.orientbell.com/catalogsearch/result?q={search_term_string}",
"query-input": "required name=search_term_string"
}
}`
    }
  }

  useEffect(() => {
    // if(window.matchMedia('(max-width: 768px)').matches){
    //   setScreenType("mobile")
    // } else {
    //   setScreenType("desktop")
    // }
  }, []);
const [homePageData] = useState(data.data.cmsBlocks);
// console.log("XYZZZZ",data.data.cmsBlocks )
  // Function to convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle file selection and validate MIME type
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      try {
        const base64String = await convertToBase64(file);
        setbase64content(base64String)
        // console.log('Base64 type:', base64String.split(',')[0]); // Debugging purpose
        // console.log('Base64 String:', base64String.split(',')[1]); // Debugging purpose
        event.target.value = null;
        // Now you can use base64String for your API call
      } catch (error) {
        console.log('Error converting image to base64:', error);
      }
    }
  }
    return (
        <>
		<Head>
      {/* <script type="text/javascript" src="https://cdn.roomvo.com/static/scripts/b2b/orientbell.js" defer=""></script> */}
        <meta charSet="utf-8" />
        <title>Orientbell Tiles: Best Tiles Company in India | Leading Tile Brand</title>
        <meta name="title" content="Orientbell Tiles: Best Tiles Company in India | Leading Tile Brand" />
        <meta
          name="description"
          content="Explore Orientbell, India's leading tile brand, with 100% Tiles, 0% Celebs. Get style & class with a wide range of premium quality tiles at the correct price in your city. Shop now!"
        />
        <meta
          name="keywords"
          content="orientbell, orientbell tiles, orientbell tiles manufacturer, tiles largest manufacturer, best tile manufacturer in india"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="pinterest-rich-pin" content="false" />
        <meta
          name="twitter:description"
          content="Explore Orientbell, India's leading tile brand, with 100% Tiles, 0% Celebs. Get style & class with a wide range of premium quality tiles at the best price. Shop now!"
        />
        <meta name="twitter:url" content="https://www.orientbell.com/" />
        <meta name="twitter:image" content={`${config.HEADLESS_BASE_URL}/twitterLogo.jpg`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="Orientbell" />
        <meta name="twitter:creator" content="Orientbell" />
        <meta name="twitter:title" content="Orientbell Tiles: Best Tiles Company in India | Leading Tile Brand" />
        <meta  property="og:title" content="Orientbell Tiles: Best Tiles Company in India | Leading Tile Brand"
        />
        <meta
          property="og:description"
          content="Explore Orientbell, India's leading tile brand, with 100% Tiles, 0% Celebs. Get style & class with a wide range of premium quality tiles at the best price. Shop now!"
        />
        <meta property="og:url" content="https://www.orientbell.com/" />
        <meta property="og:image" content={`${config.HEADLESS_BASE_URL}/twitterLogo.jpg`} />
        <link rel="alternate" hrefLang="en-in" href="https://www.orientbell.com" />
        <script 
      type="application/ld+json"
      dangerouslySetInnerHTML={ webPageSchema()}
    />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={ searchSchema()}
    />
      </Head>
      {base64content && <VisualSearch2 base64={base64content} setbase64content={setbase64content}/>}
        {scrrenType == "desktop" &&  <div className="desktopMainSlider_home_page"><MainSlider data = {homePageData.items[1]?.content} /> </div>}
        {scrrenType == "mobile" &&  <div className="MobileMainSlider_home_page"><MobileMainSlider data = {homePageData.items[10]?.content} />   </div>}	        
         
      
          <section className="oblhome-category-section new-design-mobileview">
        <div className="container">
          <div className="oblhome-category-row">
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/floor-tiles"><img src="/floor-tiles-66f6ab2a37097.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/floor-tiles">Floor Tiles</a></h6>
        
              </div>
            </div>
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/wall-tiles"><img src="/wall-tiles-66f6ab2ab81dd.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/wall-tiles">Wall Tiles</a></h6>
             
              </div>
            </div>
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/kitchen-tiles"><img src="/kitchen-tiles-66f6ab28cbe8d.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/kitchen-tiles">Kitchen Tiles</a></h6>
         
              </div>
            </div>
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/bathroom-tiles"><img src="/bathroom-tiles-66f6ab2a092c2.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/bathroom-tiles">Bathroom Tiles</a></h6>
           
              </div>
            </div>
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/wooden-tiles"><img src="/wooden-tiles-66f6ab28202ee.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/wooden-tiles">Wooden Tiles</a></h6>
     
              </div>
            </div>
            <div className="oblhome-category-col">
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/marble-tiles"><img src="/marble-tiles-66f6ab2840b61.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/marble-tiles">Marble Tiles</a></h6>
         
              </div>
            </div>
            <div className={displayAllCategories ? 'oblhome-category-col ':'oblhome-category-col remove-tiles'}>
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/living-room-tiles"><img src="/room-tiles-66f6ab29658ac.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/living-room-tiles">Room Tiles</a></h6>
           
              </div>
            </div>
            <div className={displayAllCategories ? 'oblhome-category-col ':'oblhome-category-col remove-tiles'}>
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/vitrified-tiles"><img src="/vitrified-tile-66f6ab299833b.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/vitrified-tiles">Vitrified Tiles</a></h6>
           
              </div>
            </div>
            <div className={displayAllCategories ? 'oblhome-category-col ':'oblhome-category-col remove-tiles'}>
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/ceramic-tiles"><img src="/ceramic-tiles-66f6ab27757ed.webp"  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/ceramic-tiles">Ceramic Tiles</a></h6>
              
              </div>
            </div>        
            <div className={displayAllCategories ? 'oblhome-category-col ':'oblhome-category-col remove-tiles'}>
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/cool-tiles"><img src="/cool-tiles-66f6ab2905c12.webp "  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/cool-tiles">Cool Tiles</a></h6>
             
              </div>
            </div> 

            <div className={displayAllCategories ? 'oblhome-category-col ':'oblhome-category-col remove-tiles'}>
              <div className="oblhome-category-figure">
                <a href="https://www.orientbell.com/tiles/large-tiles"><img src="/large-tiles-66f6ab27a9099.webp "  alt='image'/></a>
              </div>
              <div className="oblhome-category-figcation">
                <h6><a href="https://www.orientbell.com/tiles/large-tiles">Large Tiles</a></h6>
             
              </div>
            </div> 

               

          </div>
           { !displayAllCategories? <div class="view-all-category-btn" onClick={()=>setDisplayAllCategories(true)}><a >View More</a></div>:
           <div class="view-all-category-btn" onClick={()=>setDisplayAllCategories(false)}><a >View Less</a></div>
           }
        </div>
      </section>
          <section className="largest-collection-section new-design-mobileview">
     
        <h2> Large Tiles </h2>
        <div className="mobile-latest-sction">
          <LargeMobileSlider/>
        </div>
      </section>
      <section className="obl-LatestTrending-section new-design-mobileview">
        <h2>Explore Our Range</h2>
        <p>Tiles for your home decor</p>
        <div className="mobile-latest-sction">
          <LatestTrending/>
        </div>
      </section>
						      <HomeTrilook content={ homePageData.items[0]?.content}/>
			{/* <TrendingTiles data = {homePageData.items[2]?.content}/> */}
            
            <section className="home_features hp-features">
                <div className="container" dangerouslySetInnerHTML={{ __html: homePageData.items[3]?.content }} />
            </section>

            <section className="home-color-tile-section">
                <div className='container'>
                    <div className='home-color-tile'>
                        <h2>Find Your Color</h2>
                        <p>From subtle elegance to bold statements</p>
                    </div>
                    <div className='home-color-tile-row'>
                        <div className='home-color-tile-col'>
                            <a href="https://www.orientbell.com/tiles/white-tiles">
                                <div className='find-color-img'>
                                    <img src="shop-White.jpg" alt="image" />
                                </div>
                                <div className='find-color-text'>
                                    <h4>Whites</h4>
                                </div>
                            </a>
                        </div>
                        <div className='home-color-tile-col'>
                            <a href="https://www.orientbell.com/tiles/blue-tiles">
                                <div className='find-color-img'>
                                    <img src="shop-Blue.jpg" alt="image" />
                                </div>
                                <div className='find-color-text'>
                                    <h4>Blues</h4>
                                </div>
                            </a>
                        </div>
                        <div className='home-color-tile-col'>
                            <a href="https://www.orientbell.com/tiles/grey-tiles">
                                <div className='find-color-img'>
                                    <img src="shop-Grey.jpg" alt="image" />
                                </div>
                                <div className='find-color-text'>
                                    <h4>Greys</h4>
                                </div>
                            </a>
                        </div>
                        <div className='home-color-tile-col'>
                            <a href="https://www.orientbell.com/tiles/green-tiles">
                                <div className='find-color-img'>
                                    <img src="Shop-Green.jpg" alt="image" />
                                </div>
                                <div className='find-color-text'>
                                    <h4>Greens</h4>
                                </div>
                            </a>
                        </div>
                        <div className='home-color-tile-col lastchild-color-bx'>
                            <a href="https://www.orientbell.com/tiles/pink-tiles">
                                <div className='find-color-img'>
                                    <img src="shop-Pink.jpg" alt="image" />
                                </div>
                                <div className='find-color-text'>
                                    <h4>Pinks</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="shop-all-solor-btn">
                      <a href="https://www.orientbell.com/shop-by-color" className="default-btn shop-all-btn">Shop Tiles By Color</a>
                    </div>
                </div>
            </section>
            <section className="same_look">
             <div className="container">
               <div className="same_look_wrap flex-css">
               <div className="same_look_img">
                  <h2>Looking for a specific pattern/color ?</h2>
                  <img src="../similar-look-home.webp"  alt='image'/>
                  <span className="default-btn --syte-start-camera-upload hide-sm">
        <label style={{ cursor: 'pointer' }}>
Discover Similar Tiles                  <input
            type="file"
            accept="image/jpeg, image/png, image/tiff" // Restrict the accepted file types
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the input field
          />
        </label>
      </span>                </div>
                <div className="same_look_data">
                  <h2>Looking for a specific pattern/color ?</h2>
                  <p>We help you visually search for similar color or design patterns. Check it out now</p>
                  {/* <span className="default-btn --syte-start-camera-upload hide-sm">Click to upload an image to Discover Similar Tiles</span> */}
                  <span className="default-btn --syte-start-camera-upload hide-sm">
        <label style={{ cursor: 'pointer' }}>
        Discover Similar Tiles
                  <input
            type="file"
            accept="image/jpeg, image/png, image/tiff" // Restrict the accepted file types
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the input field
          />
        </label>
      </span>
                </div>
                
               </div>
             </div>
            </section>
			{/* <CustomerReview data={homePageData.items[5]?.content}/> */}
            <section className="home_clients client-section">
                <div className="container" dangerouslySetInnerHTML={{ __html: homePageData.items[6]?.content }} />
            </section>
            <section className="home_find_dealer find-dealer-section">
                <div className="container" dangerouslySetInnerHTML={{ __html: homePageData.items[7]?.content }} />
            </section>
            <section className="signature_iframe signature-section">
                <div className="container" dangerouslySetInnerHTML={{ __html: homePageData.items[8]?.content }} />
            </section>
			<section className="latest-updates-section">
				<div className="container">
				<h2>Latest Updates</h2>
				<div className="latest-up-row flex-class">
					<div className="latest-col">
					<h6>
						<a href={"https://www.orientbell.com/latest-news"} rel="nofollow">Media</a>
					</h6>
					<div className="home-listwrap flex-class">
						{latestUpdateData.latestmedia?.map((obj,index) => {
						return (
							<div className="home-post" key={index}>
							<div className="listingImg">
								<a href={obj.read_more_url} rel="nofollow">
								<img src={obj.thumbnailimage} alt='image'/>
								</a>
							</div>
							<div className="listingText">
								<a href={obj.read_more_url} className="post-tiles" rel="nofollow">
								{obj.title}
								</a>
								<p>{obj.short_content}</p>
								<div className="listingButton">
								<a href={obj.read_more_url} rel="nofollow">
									Read More{" "}
									<i
									className="fa fa-long-arrow-right"
									aria-hidden="true"
									></i>
								</a>
								</div>
							</div>
							</div>
						);
						})}
					</div>
					</div>
					<div className="latest-col">
					<h6>
						<a href={"https://www.orientbell.com/blog"} rel="nofollow">Blog</a>
					</h6>
					<div className="home-listwrap flex-class">
						{(relatedBlogData?.length > 0 && Array.isArray(relatedBlogData)) && relatedBlogData.map((obj,index) => {
						return (
							<div className="home-post" key={index}>
							<div className="listingImg">
								<a href={obj.permalink} rel="nofollow">
								<img src={obj.image} alt='image'/>
								</a>
							</div>
							<div className="listingText">
								<a href={obj.permalink} className="post-tiles" rel="nofollow">
                <div dangerouslySetInnerHTML={{ __html: obj.title }} />
								</a>
								<p dangerouslySetInnerHTML={{ __html: obj.content }}>
                {/* <div dangerouslySetInnerHTML={{ __html: obj.content }} /> */}
								</p>
								<div className="listingButton">
								<a href={obj.permalink} rel="nofollow">
									Read More{" "}
									<i
									className="fa fa-long-arrow-right"
									aria-hidden="true"
									></i>
								</a>
								</div>
							</div>
							</div>
						);
						})}
					</div>
					</div>
				</div>
				</div>
			</section>
        </>
    )
}

export default HomePage;
